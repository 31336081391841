<template>
    <div class="index-content">
        <div class="elegant">
            <p class="elegant-title">会员风采</p>
            <div class="elegant-list">
                <div class="elegant-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                    <div class="elegant-item-img">
                        <img :src="item.image"/>
                        <div class="elegant-item-img-title">{{ item.title }}</div>
                    </div>
                    <p class="elegant-item-p">{{ item.describe}}</p>
                </div>
            </div>

            <!-- 底部分页 -->
            <paging @changeIndex="changeIndex" :allNum="total"></paging>
        </div>
    </div>
</template>
<script>
import footers from '@/components/footers'
import paging from '@/components/paging'
export default {
    components:{
        paging
    },
    name: 'elegant',
    data(){
        return{
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/sintroduce',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'学会风采')
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },
        navto(item){
            this.$router.push({
                path:"/society/elegantDetail",
                query:{
                    id: item.id
                }
            })
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index-content{
        width: 100%;
        background: #F6F6EA;
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 1200px){
        .elegant{
            width: 100%;
        }
    }
    .elegant{
        width: 1200px;
        padding: 40px;
        background: #ffffff;
        position: relative;
    }
    .elegant-title{
        text-align: center;
        font-weight: bold;
        color: #272727;
        font-size: 30px;
        padding-bottom: 40px;
    }
    // 学院列表
    .elegant-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .elegant-item{
        width: 324px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .elegant-item-img{
        padding: 0 34px ;
        position: relative;
    }
    .elegant-item-img img{
        width: 258px;
        height: 258px;
        border-radius: 50%;
    }
    .elegant-item-img-title{
        position: absolute;
        bottom: 0;
        right: 58px;
        padding: 7px 18px;
        color:#ffffff;
        background: linear-gradient(88deg, #FE9E34 0%, #FEB86C 100%);
        border-radius: 18px;
    }
    .elegant-item-p{
        color: #656565;
        font-size: 16px;
        height: 150px;
        text-indent: 2em;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .elegant-item-p:hover{
        color:#26716C ;
    }
    .more{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }
    .more div{
        padding: 12px 34px;
        color: #46382E;
        border: 1px solid #26716C;
    }
</style>