import { render, staticRenderFns } from "./elegant.vue?vue&type=template&id=4632e35e&scoped=true&"
import script from "./elegant.vue?vue&type=script&lang=js&"
export * from "./elegant.vue?vue&type=script&lang=js&"
import style0 from "./elegant.vue?vue&type=style&index=0&id=4632e35e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4632e35e",
  null
  
)

export default component.exports